.root {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.box {
  width: 400px;
  height: 100px;
  position: absolute;
  top: -100px;
  transition: all 0.5s ease-in-out;
  right: 10px;

  .input {
    resize: none;
    width: 400px;
  }

  &.open {
    top: 0;
  }
}

.button {
  outline: none;
  border: none;
  &.success {
    background: green;

    * {
      color: white;
    }
  }
}

