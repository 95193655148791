@import 'src/App/variables';

.requestWrapper {
  display: flex;
  gap: 10px;
  padding-right: 10px;

  .console {
    background: $lightred;
    height: unset;
    width: 50%;
    border-radius: 4px;


    &.greenConsole {
      background: $lightgreen;
      .title {
        background: $green;
        color: $text;
      }

      .response {
        &::-webkit-scrollbar-track {
          background: $text!important;
        }

        &::-webkit-scrollbar-thumb {
          background: $green!important;
        }
      }
    }

    .title {
      background: $red;
      color: $text;
      padding: 10px;
      border-radius: 4px 4px 0 0;
      height: 52px;
      display: flex;
      align-items: center;
    }

    .codeWrapper {
      white-space: pre;
      max-height: inherit;
      word-break: break-all;
      text-wrap: balance;

      .status {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        color: $text;
      }

      .response {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: $text;

      }

      .errorJSON {
        width: 100%;
        font-size: 20px;
        padding: 10px;
        max-height: 300px;
        overflow-y: scroll;
        color: $text;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: $lightred;
        }

        &::-webkit-scrollbar-thumb {
          background: $darkred;
        }
      }

      .errorStatus {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: $text;
        padding: 10px;
      }
    }
  }

  .workflowWrapper {
    border-radius: 4px;
    background-color: $text;
    width: 50%;
    overflow: hidden;

    .workflow {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      align-content: center;
      background: $lightPrimary;
      border-radius: 4px;

      .workflowName {
        border-radius: 4px;
        cursor: pointer;
        align-items: center;
        display: flex;

        .requestMethod {
          color: $text;
          padding: 5px;
          border-radius: 4px;
          margin-right: 10px;
          background: $primary;
        }
      }
    }

    .jsonWrapper {
      background-color: $accent;
    }

    .workflowActionButtons {
      display: flex;
      gap: 10px;
    }

    .runButton {
      background: $green;
      color: $text;

      &:hover {
        background: $lightgreen;
        color: $text !important;
      }
    }

    .randomizeButton {
      background: $primary;
      color: $text;

      &:hover {
        background: $lightgreen;
        color: $text !important;
      }
    }
  }

}
