@import 'variables';

.root {

}

.pageBody {
  padding: 10px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background: $darkerPrimary;
  min-height: 100vh;
}
