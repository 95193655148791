$cubeSize: 100px;
.loadingWrapper {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 300px;
  animation: appearingAnim 0.5s ease forwards;
  .cube {
    justify-self: center;
    position: relative;
    width: $cubeSize;
    height: $cubeSize;
    transform-style: preserve-3d;
    transform: rotateX(-20deg) rotateY(0deg);
    user-select: none;
    animation: rotateAnim 3s linear infinite;

    & * {
      user-select: none;
    }

    & > .sides {
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      transform-style: preserve-3d;

      & > .side {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $cubeSize * 0.2;
        color: green;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(whitesmoke, grey 100%);
        border: $cubeSize * 0.01 solid white;

        &:nth-of-type(2) {
          transform: rotateY(0deg) translateZ($cubeSize / 2);
        }

        &:nth-of-type(3) {
          transform: rotateY(90deg) translateZ($cubeSize / 2);
        }

        &:nth-of-type(4) {
          transform: rotateY(180deg) translateZ($cubeSize / 2);
        }

        &:nth-of-type(5) {
          transform: rotateY(270deg) translateZ($cubeSize / 2);
        }
        &:nth-of-type(7) {
          background: transparent;
          border: none;
          transform: rotateY(270deg) translateZ($cubeSize + 20px);
          animation: rotateAnimFaster 3s linear reverse infinite;
        }
        &:nth-of-type(8) {
          background: transparent;
          border: none;
          transform: rotateY(90deg) translateZ($cubeSize + 30px);
          animation: rotateAnimFaster 3s linear reverse infinite;
        }
        &:nth-of-type(9) {
          background: transparent;
          border: none;
          transform: rotateY(-90deg) translateZ($cubeSize + 40px);
          animation: rotateAnimFaster 3s linear reverse infinite;
        }
      }
    }

    .top {
      position: absolute;
      width: $cubeSize;
      height: $cubeSize;
      top: 0;
      left: 0;
      transform: rotateX(90deg) translateZ($cubeSize / 2);
      border: $cubeSize * 0.01 solid white;
    }

    img {
      width: inherit;
      height: inherit;
    }

    .shadow {
      position: absolute;
      width: $cubeSize;
      height: $cubeSize;
      top: 0;
      left: 0;
      transform: rotateX(90deg) translateZ($cubeSize * -0.8);
      background: #000000;
      opacity: 0.5;
      filter: blur(25px);
    }
  }

}

@keyframes rotateAnim {
  0% {
    transform: rotateX(-20deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(-20deg) rotateY(360deg);
  }
}
@keyframes rotateAnimFaster {
  0% {
    transform: rotateX(10deg) rotateY(0deg) translateZ($cubeSize + 20px);
  }
  100% {
    transform: rotateX(10deg) rotateY(360deg) translateZ($cubeSize + 20px);
  }
}
@keyframes appearingAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
