$darkerPrimary: #0D47A1; /* Material Blue 900 - Deep Dark Blue */
$darkPrimary: #1565C0; /* Material Blue 800 - Rich Dark Blue */
$lightPrimary: #64B5F6; /* Material Blue 300 - Light and Bright Blue */
$primary: #1E88E5; /* Material Blue 600 - Vibrant Medium Blue */
$text: #E3F2FD; /* Material Blue 50 - Light Blue for Text */
$accent: #00ACC1; /* Material Cyan 600 - Cyan-Blue Accent */
$primaryText: #1A237E; /* Material Indigo 900 - Dark Blue-Gray for Primary Text */
$secondaryText: #3949AB; /* Material Indigo 600 - Medium Blue-Gray for Secondary Text */
$divider: #BBDEFB; /* Material Blue 100 - Very Light Blue for Dividers */
$green: #004D40; /* Material Teal 900 - Deep Teal Green */
$lightgreen: #26A69A; /* Material Teal 400 - Bright Light Teal */
$red: #880E4F; /* Material Pink 900 - Deep Red with a Hint of Blue */
$darkred: #C2185B; /* Material Pink 700 - Darker Red with a Hint of Blue */
$lightred: #F8BBD0; /* Material Pink 100 - Soft Light Red */
