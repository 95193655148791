@import 'src/App/variables';

.editor {
  width: 100%;
  padding: 10px;
  background: $text;
  gap: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 200px;

  .buttonsWrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .viewEditButton {
      background: $darkerPrimary;
      color: $text;
      border-radius: 4px;
      text-align: center;
      padding: 0px 5px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.view {
        background: $red;
      }

      &.edit {
        background: $green;
      }

      &:hover {
        background: $primary;
        color: $text;
      }
    }

    button {
      margin-left: 10px;
    }
  }
}

.clipboardWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .clipboard {
    color: $text;
    display: flex;
    gap: 10px;
    justify-content: space-between;

    span {
      border-radius: 4px;
      padding: 5px;
    }
    span:first-child {
      background: $green;
    }

    span:last-child {
      background: $green;
    }

    &.corrupted {
      span {
        position: relative;
      }
      span:first-child {
        background: $darkerPrimary;
      }

      span:last-child {
        background: $darkerPrimary;
      }

      code {
        position: absolute;
        background: red;
        border-radius: 4px;
        height: 10px;
        width: 50px;
        text-align: center;
        top: 0;
        left: -2px;
        font-size: 8px;
      }
    }
  }
}

.field {
  display: flex;
  gap: 10px;
  align-items: center;

  .fieldName {
    color: $darkerPrimary;
    padding: 5px 0;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .fieldValue {
    background: $darkerPrimary;
    color: $text;
    border-radius: 4px;
    text-align: center;
    padding: 0px 5px;
    min-height: 30px;
    display: flex;
    align-items: center;
    min-width: 100px;

    .fieldInput {
      min-width: 100px;
    }

    &.fieldValueEdited {
      background: none;
    }
  }
}

.randomizer {
  background: $darkerPrimary;
  min-height: 100px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .randomizerRow {
    display: flex;
    gap: 10px;
  }

  .randomizerButton {
    background: $red;
    color: $text;

    &.enabled {
      background: $green;
      color: $text;
    }

    &:hover {
      color: $text !important;
    }
  }
}

.enumArrayField {
  display: flex;
  gap: 10px;

  &.column {
    flex-direction: column;
  }

  .badgeList {
    display: flex;
    gap: 2px;
    flex-wrap: wrap;

    .badge {
      background: $darkerPrimary;
      color: $text;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      cursor: pointer;

      &:hover {
        background: $primary;
        color: $text;
      }
    }

  }
}
